<template>
  <v-row class="ma-0 test" justify="space-between">
    <v-col cols="1" class="pa-0">
      <v-btn class="button elevation-5" @click="returnRouterOrders">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="2" class="pa-0 d-flex" style="font-size: 1.25em">
      <span class="ma-auto">Order # {{ this.tabDetails["nereus_no"] }} </span>
    </v-col>

    <v-divider vertical />

    <v-col cols="3" class="pa-0 d-flex" style="font-size: 1.25em">
      <span style="color: grey; font-weight: 400" class="ma-auto"
        >"{{ this.tabDetails["coName"] }}"</span
      >
    </v-col>

    <v-divider vertical />

    <v-col
      class="px-2 d-flex ma-auto"
      :style="{ fontSize: '1.25em', flex: 'none', width: 'auto' }"
    >
      <v-chip
        v-if="this.tabDetails['vendStepDescr'] == 'Quote'"
        label
        class="ma-"
        color="grey"
      >
        {{ this.tabDetails["vendStepDescr"] }}
      </v-chip>

      <v-chip
        v-if="this.tabDetails['vendStepDescr'] == 'New'"
        label
        class="ma-"
        color="indigo"
      >
        {{ this.tabDetails["vendStepDescr"] }}
      </v-chip>

      <v-chip
        v-if="this.tabDetails['vendStepDescr'] == 'Paid'"
        label
        class="ma-"
        color="green"
      >
        {{ this.tabDetails["vendStepDescr"] }}
      </v-chip>

      <v-chip
        v-if="this.tabDetails['vendStepDescr'] == '---'"
        label
        class="ma-"
        color="primary"
      >
        {{ this.tabDetails["vendStepDescr"] }}
      </v-chip>
    </v-col>

    <v-col class="row justify-end" style="gap: 10px">
      <template>
        <v-tooltip
          v-for="{ name, color, tooltipText, method } in buttonsName"
          :key="name"
          bottom
          color="primary"
          :open-on-hover="!!tooltipText.length"
          :open-on-focus="!!tooltipText.length"
          :open-on-click="!!tooltipText.length"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="button elevation-5"
              :color="color || 'primary'"
              v-on="on"
              @click="handlerEventButton(method)"
            >
              {{ name }}
            </v-btn>
          </template>
          <span>{{ tooltipText }}</span>
        </v-tooltip>
      </template>
      <div :style="{ width: '20%', minWidth: '200px' }">
        <v-select
          v-if="this.tabDetails['docType'] != 'RFQ'"
          v-model="downloadSelect"
          :items="downloadItems"
          item-text="title"
          item-value="value"
          label="Download"
          single-line
          dense
          solo
          background-color="#20212E"
          hide-details
          class="lighten-2 pa-0 ma-0 elevation-5 text-center outlined-button"
        >
        </v-select>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
button {
  height: 36px;
  min-width: 64px;
  padding: 0 7px;
  font-size: 0.75em;
}
</style>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { update_order_pipeline } from "@/helpers/api/Orders";
import axios from "axios";
import { remote_upload_pdf_to_order } from "@/helpers/api/Orders/index.js";
import { trans_log_order } from "@/helpers/api/Orders/index.js";

export default {
  porps: [],
  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
    }),
    tabDetails() {
      return this.tabSelected.details;
    },
    downloadItems() {
      let items = [];

      if (this.tabDetails["vendStepDescr"] == "Quote Available")
        items.push({ title: "Download Quote", value: "0" });

      if (this.tabDetails["docType"] == "Invoice")
        items.push({ title: "Download Bill Of Sale", value: "1" });
      if (
        this.tabDetails["vendStepDescr"] == "Release Available" ||
        this.tabDetails["vendStepDescr"] == "Pending Pick Up" ||
        this.tabDetails["vendStepDescr"] == "Picked Up"
      )
        items.push({ title: "Download Release", value: "2" });

      return items;
    },
    buttonsName() {
      let statuses = {
        Quote: [
          {
            name: "Print/Send Quote",
            tooltipText: "Print Quote",
            method: "downloadQuote",
          },
          {
            name: "Generate Open Order",
            tooltipText: "",
            method: "quote2OpenOrder",
          },
          {
            name: "Cancel Quote",
            tooltipText: "",
            color: "red darken-4",
            method: "cancelOrder",
          },
          {
            name: "updateOrderPipeline",
            tooltipText: "",
            color: "red darken-4",
            method: "updateOrderPipeline",
          },
        ],
        New: [
          // {
          //   name: "Send Bill",
          //   tooltipText: "Send Bill To Customer",
          // },

          {
            name: "Print/Send Bill of Sale",
            tooltipText: "",

            method: "downloadInvoice",
          },

          {
            name: "Send Pay Reminder",
            tooltipText: "Send Pay Reminder to Buyer",
          },

          {
            name: "Customer Payment",
            tooltipText: "",

            method: "showCustomerPayment",
          },

          {
            name: "Cancel Order",
            tooltipText: "",
            color: "red darken-4",
            method: "cancelOrder",
          },
          {
            name: "updateOrderPipeline",
            tooltipText: "",
            color: "red darken-4",
            method: "updateOrderPipeline",
          },
        ],
        Paid: [
          {
            name: "Set Release - All",
            tooltipText: "Set Release for all line items",
            method: "setupRelease",
          },
          {
            name: "Issue Release",
            tooltipText: "Issue Release for Paid-For Units",
            method: "downloadRelease",
          },
          // {
          //   name: "Update Payment",
          //   tooltipText: "Update Payment Amount & Reference #",
          // },
          {
            name: "Cancel Order",
            tooltipText: "",
            color: "red darken-4",
            method: "cancelOrder",
          },
          {
            name: "updateOrderPipeline",
            tooltipText: "",
            color: "red darken-4",
            method: "updateOrderPipeline",
          },
        ],
        "Pending Pick Up": [
          // {
          //   name: "Send Pickup reminder",
          //   tooltipText: "Send Pickup reminder to Buyer",
          // },
          {
            name: "Re-issue Release",
            tooltipText: "Issue Release for Paid-For Units",
            method: "downloadRelease",
          },
          {
            name: "Cancel Order",
            tooltipText: "",
            color: "red darken-4",
            method: "cancelOrder",
          },
          {
            name: "updateOrderPipeline",
            tooltipText: "",
            color: "red darken-4",
            method: "updateOrderPipeline",
          },
        ],
        Completed: [
          // {
          //   name: "Request Rebill",
          //   tooltipText: "Request Rebill from Finance/ERP",
          // },
          // {
          //   name: "Send Rebill ",
          //   tooltipText: "Send Rebill to Buyer",
          // },
          {
            name: "updateOrderPipeline",
            tooltipText: "",
            color: "red darken-4",
            method: "updateOrderPipeline",
          },
        ],
      };

      return statuses[`${this.tabDetails["vendStepDescr"]}`.trim()];
    },
  },
  data() {
    return {
      downloadSelect: 0,
    };
  },
  methods: {
    ...mapActions({
      actionCancelOrder: "moduleOrders/cancelOrder",
      actionQuote2OpenOrder: "moduleOrders/quote2OpenOrder",
      updateDetails: "moduleOrders/moduleDetails/updateDetails",
    }),
    ...mapMutations({
      setDetailsStore: "moduleOrders/setDetails",
    }),

    returnRouterOrders() {
      this.setDetailsStore({});
      this.$router.replace("/orders");
    },
    handlerEventButton(method) {
      if (this[method]) this[method]();
    },
    async cancelOrder() {
      let { docType, id } = this.tabDetails;
      await this.actionCancelOrder({
        docType,
        orderId: id,
      });
      // this.returnRouterOrders();

      setTimeout(function () {
        window.location.reload();
      }, 1000);
    },
    async downloadInvoice() {
      const token = localStorage.getItem("access_token");
      let { docNo, id } = this.tabDetails;
      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;

      window.open(
        `${url}/rptVendToCustBOS_v2/?v_invoiceID=${id}&v_invoiceNO=${docNo}&token=${token}`,
        "_blank",
      );

      this.updateDetails({ name: "" });

      try {
        trans_log_order({
          order_id: id,
          doc_table: "invoices",
          trans_desc: "Bill of Sale print/view opened",
          application: "rptVendToCustBOS_v2",
          trans_action: "Print",
          old_value: "",
          new_value: "",
        });
      } catch (error) {
        console.log(error);
      }

      remote_upload_pdf_to_order({
        url: `https://pdf-gen-service-vjuqlq6gsq-uw.a.run.app/?url=${url}/rptVendToCustBOS_v2/?v_invoiceID=${id}&v_invoiceNO=${docNo}&token=${token}`,
        docType: "SALEINVOICE",
        category: "BOS",
        sourceType: "FILEATTACH",
        sourceid: docNo,
        permissions: "write,read",
        organization_id: null,
      });
    },
    async downloadQuote() {
      const token = localStorage.getItem("access_token");
      let { docNo, id } = this.tabDetails;
      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;
      window.open(
        `${url}/rptVendToCustQuote/?v_quoteID=${id}&v_quoteNO=${docNo}&token=${token}`,
        "_blank",
      );

      // try {
      //   trans_log_order({
      //     order_id: id,
      //     doc_table: "quotes",
      //     trans_desc: "Quote print/view opened",
      //     application: "rptVendToCustQuote",
      //     trans_action: "Print",
      //     old_value: "",
      //     new_value: "",
      //   });
      // } catch (error) {
      //   console.log(error);
      // }

      remote_upload_pdf_to_order({
        url: `https://pdf-gen-service-vjuqlq6gsq-uw.a.run.app/?url=${url}/rptVendToCustQuote/?v_quoteID=${id}&v_quoteNO=${docNo}&token=${token}`,
        docType: "SALEINVOICE",
        category: "QUOTE",
        sourceType: "FILEATTACH",
        sourceid: docNo,
        permissions: "write,read",
        organization_id: null,
      });
    },
    showCustomerPayment() {
      this.$emit("showCustomerPayment", true);
    },
    async quote2OpenOrder() {
      // console.log("this.tabDetails", this.tabDetails);
      // let { docType, id } = this.tabDetails;

      // await this.actionQuote2OpenOrder({
      //   id: this.tabDetails.id,
      // });

      this.$store.commit("setSpinnerShow", true);

      const token = localStorage.getItem("access_token");
      let { docNo, id } = this.tabDetails;
      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;

      const { data } = await axios.post(
        `https://corsanywhere-vjuqlq6gsq-wl.a.run.app/${url}/api_quote2invoice2_vendapp/?token=${token}`,
        {
          id: id,
        },
      );

      this.$store.commit("setSpinnerShow", false);

      this.returnRouterOrders();
    },
    setupRelease() {
      this.$emit("showIssueRelease", true);
      // const token = localStorage.getItem("access_token");
      // let { docNo, id } = this.tabDetails;
      // const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;
      // window.open(
      //   `${url}/rptVendToCustRelNote/?v_invoiceID=${id}&v_invoiceNO=${docNo}&token=${token}`,
      //   "_blank",
      // );
    },
    async downloadRelease() {
      // this.$emit("showIssueRelease", true);
      const token = localStorage.getItem("access_token");
      let { docNo, id } = this.tabDetails;
      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;

      window.open(
        `${url}/rptVendToCustRelNote/?v_invoiceID=${id}&v_invoiceNO=${docNo}&token=${token}`,
        "_blank",
      );

      try {
        trans_log_order({
          order_id: id,
          doc_table: "release_note",
          trans_desc: "Release print/view opened",
          application: "rptVendToCustQuote",
          trans_action: "Print",
          old_value: "",
          new_value: "",
        });
      } catch (error) {
        console.log(error);
      }

      remote_upload_pdf_to_order({
        url: `https://pdf-gen-service-vjuqlq6gsq-uw.a.run.app/?url=${url}/rptVendToCustRelNote/?v_invoiceID=${id}&v_invoiceNO=${docNo}&token=${token}`,
        docType: "SALEINVOICE",
        category: "REL",
        sourceType: "FILEATTACH",
        sourceid: docNo,
        permissions: "write,read",
        organization_id: null,
      });

      setTimeout(function () {
        window.location.reload();
      }, 4000);
    },

    async updateOrderPipeline() {
      let { docType, id } = this.tabDetails;
      const resData = await update_order_pipeline({ invoice_id: id });
    },
  },
};
</script>

<style lang="scss" scoped></style>
